var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.loading && !_vm.permissions.can_list)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('p',{staticClass:"text-h5 mb-4"},[_vm._v("Bank Branch Management")]),_c('p',{staticClass:"text-title mb-0"},[_vm._v("You do not have permission to view this bank's all branches")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xs":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.back}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)]}}],null,false,3143309572)},[_c('span',[_vm._v("Back")])])],1)],1):_vm._e(),(_vm.permissions.can_list)?[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('v-sheet',{staticClass:"pa-0",attrs:{"color":"white","elevation":"0"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('p',{staticClass:"text-h5 mb-0",domProps:{"textContent":_vm._s(_vm.object.name)}})]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xs":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.back}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)]}}],null,false,3143309572)},[_c('span',[_vm._v("Back")])])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('v-sheet',{staticClass:"pa-0",attrs:{"color":"white","elevation":"0"}},[_c('p',{staticClass:"text-h6 mb-4"},[_vm._v("Search")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"4","lg":"4","xl":"4"}},[_c('v-text-field',{attrs:{"dense":"","clearable":"","hide-details":"","label":"Branch Name"},model:{value:(_vm.filters.name),callback:function ($$v) {_vm.$set(_vm.filters, "name", $$v)},expression:"filters.name"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"8","lg":"8","xl":"8","order":"last"}},[_c('v-btn',{staticClass:"mr-2 elevation-0",attrs:{"small":"","color":"primary","ripple":false},on:{"click":_vm.search}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-magnify")]),_vm._v(" Search ")],1),_c('v-btn',{staticClass:"elevation-0",attrs:{"small":"","ripple":false},on:{"click":_vm.reset}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-undo")]),_vm._v(" Reset ")],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":"","loading-text":"Loading... Please wait...","no-data-text":"No data available yet","loading":_vm.loading,"items-per-page":50,"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',[_c('td',{staticClass:"text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center",domProps:{"textContent":_vm._s(index + 1)}}),_c('td',{staticClass:"text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center",domProps:{"textContent":_vm._s(item.name)}}),_c('td',{staticClass:"text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center",domProps:{"textContent":_vm._s(item.reference_code)}}),_c('td',{staticClass:"text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center",domProps:{"textContent":_vm._s(item.branch_code)}})])]}}],null,false,2600073335)})],1)],1),(!_vm.loading && _vm.pagination.total_pages > 1)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('v-pagination',{attrs:{"circle":"","length":_vm.pagination.total_pages,"total-visible":7},on:{"input":_vm.get},model:{value:(_vm.filters.page),callback:function ($$v) {_vm.$set(_vm.filters, "page", $$v)},expression:"filters.page"}})],1)],1):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }